import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

export default function Koleksiyonlar() {
  return (
    <Layout>
      <SEO title="Koleksiyonlar" />
    </Layout>
  )
}
